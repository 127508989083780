<template>
  <div class="tar-mobile-nav" :class="{active: mobileNav}">
    <div class="content">
      <div class="store-link">
        <a href="https://tarofficial.bigcartel.com/" target=_blank>STORE</a>
      </div>
      <AppSocialMediaLinks />
    </div>
  </div>
</template>

<script>
import AppSocialMediaLinks from '@/components/AppSocialMediaLinks/AppSocialMediaLinks.vue';

export default {
  name: 'TheMobileNav',
  props: ['mobileNav'],
  components: {
    AppSocialMediaLinks,
  },
};
</script>

<style lang="scss">
  @import './style.scss';
</style>

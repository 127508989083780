<template>
  <div class="heroCover">
    <img class="cover" src="@/assets/cover/hero-cover.jpg" alt="hero-cover">
  </div>
</template>

<script>
export default {
  name: 'TheHero',
};
</script>

<style lang="scss">
  @import './index';
</style>

<template>
  <div class="tar-app">
      <transition name="slide-fade">
        <TheMobileNav :mobileNav="toggleMobileNav" v-if="toggleMobileNav"/>
      </transition>
      <TheHeader @my-event="onToggleMobileNav"/>
      <transition name="slide-fade">
        <TheHero v-if="show"/>
      </transition>
  </div>
</template>

<script>
import TheHero from '@/components/TheHero/TheHero.vue';
import TheHeader from '@/components/TheHeader/TheHeader.vue';
import TheMobileNav from '@/components/TheMobileNav/TheMobileNav.vue';

export default {
  components: {
    TheHero,
    TheHeader,
    TheMobileNav,
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false,
      toggleMobileNav: false,
    };
  },
  methods: {
    onToggleMobileNav() {
      this.toggleMobileNav = !this.toggleMobileNav;
      if (this.toggleMobileNav) document.body.style.setProperty('overflow', 'hidden');
      else document.body.style.removeProperty('overflow', 'hidden');
    },
  },
};

</script>

<style lang="scss">
 .tar-app {
   background: black;
   display: flex;
   flex-direction: column;
   width: 100%;
 }
.slide-fade-enter-active {
  transition: all 800ms;
}

.slide-fade-leave-active {
  transition: all 300ms;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translatey(-40px);
  opacity: 0;
}
</style>

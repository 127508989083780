<template>
  <header ref="headerRef" :class="headerClass">
    <div class="tar-nav">
        <div @click="toggleMobileNav" class="mobile-nav" >
          <svg viewBox="0 0 100 20" width="300" heigth="300" xmlns="http://www.w3.org/2000/svg">
            <circle :class="{active}" class="cA" cx="10" cy="10" r="3"/>
            <circle :class="{active}" class="cB" cx="10" cy="10" r="6"/>
          </svg>
        </div>
        <div :class="logoClass">
          <img src="@/assets/logo/tar-gold.png" alt="logo-gold">
        </div>
        <div class="tar-store">
          <a href="https://tarofficial.bigcartel.com/" target=_blank>STORE</a>
        </div>
        <AppSocialMediaLinks />
    </div>
  </header>
</template>

<script>
import AppSocialMediaLinks from '@/components/AppSocialMediaLinks/AppSocialMediaLinks.vue';

export default {
  name: 'TheHeader',
  props: ['browserWidth'],
  components: {
    AppSocialMediaLinks,
  },
  data() {
    return {
      scrollPosition: null,
      headerHeight: null,
      active: false,
    };
  },
  mounted() {
    const header = this.$refs.headerRef;
    this.headerHeight = header.offsetTop + header.offsetHeight;
    window.addEventListener('scroll', this.updateScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggleMobileNav() {
      this.active = !this.active;
      this.$emit('my-event');
    },
  },
  computed: {
    headerClass() {
      if (this.scrollPosition > this.headerHeight) return 'tar-header fixed';
      return 'tar-header';
    },
    logoClass() {
      if (this.scrollPosition > this.headerHeight) return 'tar-logo logo-sm';
      return 'tar-logo';
    },
  },
};
</script>

<style lang="scss">
 @use './style.scss'
</style>

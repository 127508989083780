<template>
  <div class="tar-links">
    <a :href="link.url" v-for="link in links" :key="link.id">
      <fa :icon="['fab', link.id]" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppSocialMediaLinks',
  data() {
    return {
      links: [
        {
          id: 'youtube',
          url: 'https://www.youtube.com/channel/UCWwBxdds3ZjmlrO45utD74A',
        },
        {
          id: 'spotify',
          url: 'https://open.spotify.com/artist/2kzTGbtLVkZawrhqRkMHQo?si=6SwjbW2sSeaF-YLbvglROQ',
        },
        {
          id: 'facebook',
          url: 'https://www.facebook.com/TarBandOfficial',
        },
        {
          id: 'instagram',
          url: 'https://www.instagram.com/tar_official',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @use './style.scss';
</style>
